import React from "react";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const {
    data: {
      sections: { aboutUs },
    },
  } = useSelector((state) => state.data);

  return (
    <section id="about-us" className="about-section section text-center">
      <div className="container">
        <h3 className="section-title text-center">{aboutUs.title}</h3>
        <div className="row p-3 justify-content-between">
          <div className="col-12">
            <div className="about-us__text">
              {aboutUs.text.map((p) => (
                <p key={p}>{p}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
